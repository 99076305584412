/* Este arquivo é gerado automaticamente */

import generateAgentReportHeatmapData from './agentReport/heatmap/generateAgentReportHeatmapData.definition'
import searchAgentIntersections from './agentReport/intersections/searchAgentIntersections.definition'
import appSebraeNewAttendance from './appSebrae/appSebraeNewAttendance/newAttendanceAppSebrae/appSebraeNewAttendance.definition'
import synchronizeAppSebrae from './appSebrae/appSebraeNewAttendance/synchronizeAppSebrae/synchronizeAppSebrae.definition'
import attendanceGeoReport from './appSebrae/attendanceGeoReport/attendanceGeoReport.definition'
import attendanceRadar from './appSebrae/attendanceRadar/attendanceRadar.definition'
import fetchAttendanceProximity from './appSebrae/fetchAttendanceProximity/fetchAttendanceProximity.definition'
import fetchItinerary from './appSebrae/fetchItinerary/fetchItinerary.definition'
import fetchLegalEntityDetails from './appSebrae/fetchLegalEntityDetails/fetchLegalEntityDetails.definition'
import fetchLegalEntityItineraryStatus from './appSebrae/fetchLegalEntityItineraryStatus/fetchLegalEntityItineraryStatus.definition'
import geoSearch from './appSebrae/geoSearch/geoSearch.definition'
import legalEntityInsight from './appSebrae/legalEntityInsight/legalEntityInsight.definition'
import legalEntityNewlyOpened from './appSebrae/legalEntityNewlyOpened/legalEntityNewlyOpened.definition'
import legalEntitySearchWithGeo from './appSebrae/legalEntitySearchWithGeo/legalEntitySearchWithGeo.definition'
import reverseGeocoding from './appSebrae/reverseGeocoding/reverseGeocoding.definition'
import searchLegalEntity from './appSebrae/searchLegalEntity/searchLegalEntity.definition'
import selectItinerary from './appSebrae/selectItinerary/selectItinerary.definition'
import fetchAttendantFromRadarAli from './attendant/fetchAttendantFromRadarAli/fetchAttendantFromRadarAli.definition'
import attendantRead from './attendant/read/attendantRead.definition'
import attendantServiceUnitRead from './attendantServiceUnit/read/attendantServiceUnitRead.definition'
import fetchOpenStreetMapId from './city/fetchOpenStreetMapId/fetchOpenStreetMapId.definition'
import cityRead from './city/read/cityRead.definition'
import seedCityGovernmentTransparency from './cityGovernmentTransparency/seedCityGovermnentTransparency/seedCityGovernmentTransparency.definition'
import cityCensusByAreaRead from './cityIbgeCensus/cityCensusByArea/read/cityCensusByAreaRead.definition'
import cityCensusByPopulationRead from './cityIbgeCensus/cityCensusByPopulation/read/cityCensusByPopulationRead.definition'
import fetchLatestCityCensus from './cityIbgeCensus/fetchLatestCityCensus/fetchLatestCityCensus.definition'
import cityIbgeCensusRead from './cityIbgeCensus/read/cityIbgeCensusRead.definition'
import seedCityCensus from './cityIbgeCensus/seedCityCensus/seedCityCensus.definition'
import legalEntityUnnestCnaeRead from './cnae/legalEntityUnnestCnae/read/legalEntityUnnestCnaeRead.definition'
import cnaeRead from './cnae/read/cnaeRead.definition'
import cnaeClassRead from './cnaeClass/read/cnaeClassRead.definition'
import cnaeDivisionRead from './cnaeDivision/read/cnaeDivisionRead.definition'
import cnaeGroupRead from './cnaeGroup/read/cnaeGroupRead.definition'
import cnaeSectionRead from './cnaeSection/read/cnaeSectionRead.definition'
import cnaeSegmentRead from './cnaeSegment/read/cnaeSegmentRead.definition'
import cnaeSubclassRead from './cnaeSubclass/read/cnaeSubclassRead.definition'
import countryRead from './country/read/countryRead.definition'
import fetchCourseProduct from './courseProduct/fetchCourseProduct/fetchCourseProduct.definition'
import courseProductRead from './courseProduct/read/courseProductRead.definition'
import courseRegisterIndividualRead from './courseRegisterIndividual/read/courseRegisterIndividualRead.definition'
import fetchDataSourceHelpers from './dataSource/fetchDataSourceHelpers/fetchDataSourceHelpers.definition'
import dataSourceRead from './dataSource/read/dataSourceRead.definition'
import establishmentRead from './establishment/read/establishmentRead.definition'
import individualAnonymization from './individual/anonymization/individualAnonymization.definition'
import individualCpfValidator from './individual/cpfValidator/individualCpfValidator.definition'
import individualFetch from './individual/fetch/individualFetch.definition'
import individualRead from './individual/read/individualRead.definition'
import geocodeAddress from './internalGeocode/geocodeAddress/geocodeAddress.definition'
import internalGeocode from './internalGeocode/internalGeocode.definition'
import streetSearchRefreshViews from './internalGeocode/streetSearchRefreshViews/streetSearchRefreshViews.definition'
import cnpjValidator from './legalEntity/cnpjValidator/cnpjValidator.definition'
import createLegalEntitySearchIndex from './legalEntity/createSearchIndex/createLegalEntitySearchIndex.definition'
import legalEntityFetch from './legalEntity/fetch/legalEntityFetch.definition'
import legalEntityRead from './legalEntity/read/legalEntityRead.definition'
import attendanceRefreshViews from './legalEntityAttendance/attendanceRefreshViews/attendanceRefreshViews.definition'
import disproportionatePublicRead from './legalEntityAttendance/disproportionateAttention/disproportionatePublic/read/disproportionatePublicRead.definition'
import legalEntityAttendanceFetch from './legalEntityAttendance/fetch/legalEntityAttendanceFetch.definition'
import legalEntityAttendanceRead from './legalEntityAttendance/read/legalEntityAttendanceRead.definition'
import legalEntityAttendanceAttendantRead from './legalEntityAttendanceAttendant/read/legalEntityAttendanceAttendantRead.definition'
import legalEntityAttendanceDataSourceRead from './legalEntityAttendanceDataSource/read/legalEntityAttendanceDataSourceRead.definition'
import prependCommaAtCnaes from './legalEntityCnae/prependCommaAtCnaes/prependCommaAtCnaes.definition'
import legalEntityCnaeRead from './legalEntityCnae/read/legalEntityCnaeRead.definition'
import seedLegalEntityCnaes from './legalEntityCnae/seedLegalEntityCnaes/seedLegalEntityCnaes.definition'
import legalEntityGeoprocessingAfterProcess from './legalEntityGeoprocessing/afterProcess/legalEntityGeoprocessingAfterProcess.definition'
import generateHeatmapData from './legalEntityGeoprocessing/heatmap/generateHeatmapData/generateHeatmapData.definition'
import courseEvasionRead from './legalEntityGeoprocessing/map/ead/courseEvasion/courseEvasionRead.definition'
import eadReadCompany from './legalEntityGeoprocessing/map/ead/readCompany/eadReadCompany.definition'
import eadReadEnrollment from './legalEntityGeoprocessing/map/ead/readEnrollment/eadReadEnrollment.definition'
import eadReadStudent from './legalEntityGeoprocessing/map/ead/readStudent/eadReadStudent.definition'
import eadReadStudentCompany from './legalEntityGeoprocessing/map/ead/readStudentCompany/eadReadStudentCompany.definition'
import mapDemarcationExportEntities from './legalEntityGeoprocessing/mapDemarcation/exportEntities/mapDemarcationExportEntities.definition'
import mapDemarcationReport from './legalEntityGeoprocessing/mapDemarcation/fetchFromDatabase/mapDemarcationReport.definition'
import legalEntityGeoprocessingProcess from './legalEntityGeoprocessing/process/legalEntityGeoprocessingProcess.definition'
import radar from './legalEntityGeoprocessing/radar/fetchFromDatabase/radar.definition'
import legalEntityGeoprocessingRead from './legalEntityGeoprocessing/read/legalEntityGeoprocessingRead.definition'
import legalEntityIndividualRead from './legalEntityIndividual/read/legalEntityIndividualRead.definition'
import fetchLegalEntityIndicators from './legalEntityProfit/fetchLegalEntityIndicators/fetchLegalEntityIndicators.definition'
import legalEntityIndicatorsRead from './legalEntityProfit/read/legalEntityIndicatorsRead.definition'
import legalNatureRead from './legalNature/read/legalNatureRead.definition'
import macroRegionRead from './macroRegion/read/macroRegionRead.definition'
import mesoRegionRead from './mesoRegion/read/mesoRegionRead.definition'
import microRegionRead from './microRegion/read/microRegionRead.definition'
import migrationMigrateOlap from './migration/migrateOlap/migrationMigrateOlap.definition'
import migrationMigrateOltp from './migration/migrateOltp/migrationMigrateOltp.definition'
import neighborhoodLowerQualityBoundaries from './neighborhood/neighborhoodLowerQualityBoundaries/neighborhoodLowerQualityBoundaries.definition'
import neighborhoodNewShapes from './neighborhood/prepareForNewShapes/neighborhoodNewShapes.definition'
import neighborhoodProcess from './neighborhood/process/neighborhoodProcess.definition'
import neighborhoodRead from './neighborhood/read/neighborhoodRead.definition'
import seedNeighborhoodNullableCenter from './neighborhood/seedNeighborhoodNullableCenter/seedNeighborhoodNullableCenter.definition'
import neighborhoodStreetIntersectionProcess from './neighborhoodStreetIntersection/process/neighborhoodStreetIntersectionProcess.definition'
import neighborhoodStreetIntersectionRead from './neighborhoodStreetIntersection/read/neighborhoodStreetIntersectionRead.definition'
import deregisterDevice from './notifications/deregisterDevice/deregisterDevice.definition'
import getNotifications from './notifications/getNotifications/getNotifications.definition'
import getNotificationSettings from './notifications/getNotificationSettings/getNotificationSettings.definition'
import readNotification from './notifications/readNotification/readNotification.definition'
import registerNewDevice from './notifications/registerNewDevice/registerNewDevice.definition'
import sendNotification from './notifications/sendNotification/sendNotification.definition'
import sendTimeSeriesNotification from './notifications/sendTimeSeriesNotification/sendTimeSeriesNotification.definition'
import updateTimeSeriesNotificationSettings from './notifications/updateTimeSeriesNotificationSettings/updateTimeSeriesNotificationSettings.definition'
import reportRead from './report/read/reportRead.definition'
import reportOutputRead from './reportOutput/read/reportOutputRead.definition'
import segmentRead from './segment/read/segmentRead.definition'
import serviceUnitGeocode from './serviceUnitGeocode/serviceUnitGeocode.definition'
import serviceUnitGeoprocessingRead from './serviceUnitGeoprocessing/read/serviceUnitGeoprocessingRead.definition'
import fetchOpenStreetMapStateId from './state/fetchOpenStreetMapId/fetchOpenStreetMapStateId.definition'
import stateRead from './state/read/stateRead.definition'
import fetchAllRoads from './street/fetchAllRoads/fetchAllRoads.definition'
import fetchAllStreetsAndWays from './street/fetchAllStreetsAndWays/fetchAllStreetsAndWays.definition'
import fetchAllSuburbs from './street/fetchAllSuburbs/fetchAllSuburbs.definition'
import fetchStreetsAndWays from './street/fetchStreetsAndWays/fetchStreetsAndWays.definition'
import fetchSuburbs from './street/fetchSuburbs/fetchSuburbs.definition'
import streetFullGeocoding from './street/fullGeocoding/streetFullGeocoding.definition'
import streetRead from './street/read/streetRead.definition'
import refreshPgRoutingNetwork from './street/refreshPgRoutingNetwork/refreshPgRoutingNetwork.definition'
import seedBrasiliaAbbreviatures from './street/seedBrasiliaAbbreviatures/seedBrasiliaAbbreviatures.definition'
import seedStreetCollection from './street/seedCollections/seedStreetCollection.definition'
import seedStreetRoadReference from './street/seedStreetRoadReference/seedStreetRoadReference.definition'
import fetchSummary from './summary/fetchSummary/fetchSummary.definition'
import abortPendingUserQueries from './system/abortPendingUserQueries/abortPendingUserQueries.definition'
import systemCheck from './system/check/systemCheck.definition'
import systemCheckImports from './system/checkImports/systemCheckImports.definition'
import importCagedData from './system/importCaged/importCagedData.definition'
import systemImportEad from './system/importEad/systemImportEad.definition'
import importFtpPostgresDumps from './system/importFtpPostgresDumps/importFtpPostgresDumps.definition'
import systemPrepareCache from './system/prepareCache/systemPrepareCache.definition'
import systemPropagateToOlap from './system/propagateToOlap/systemPropagateToOlap.definition'
import systemExportRfbGeoSftp from './system/systemExportRfbGeoSftp/systemExportRfbGeoSftp.definition'
import systemImportSebraeNaSuaEmpresa from './system/systemImportSebraeNaSuaEmpresa/systemImportSebraeNaSuaEmpresa.definition'
import timeSeriesRead from './timeSeries/read/timeSeriesRead.definition'
import fetchLegalEntitiesCoordinates from './uge/fetchLegalEntitiesCoordinates/fetchLegalEntitiesCoordinates.definition'
import userLogRead from './userLog/read/userLogRead.definition'
import wayRead from './way/read/wayRead.definition'
import disproportionateAttendancesRead from './ZZZZZZZZ_placeholders/disproportionateAttendancesRead/disproportionateAttendancesRead.definition'
import legalEntityAttendanceLastMonthRead from './ZZZZZZZZ_placeholders/legalEntityAttendanceLastMonthRead/legalEntityAttendanceLastMonthRead.definition'
import legalEntityAttendanceLastYearRead from './ZZZZZZZZ_placeholders/legalEntityAttendanceLastYearRead/legalEntityAttendanceLastYearRead.definition'

export default [
  generateAgentReportHeatmapData,
  searchAgentIntersections,
  appSebraeNewAttendance,
  synchronizeAppSebrae,
  attendanceGeoReport,
  attendanceRadar,
  fetchAttendanceProximity,
  fetchItinerary,
  fetchLegalEntityDetails,
  fetchLegalEntityItineraryStatus,
  geoSearch,
  legalEntityInsight,
  legalEntityNewlyOpened,
  legalEntitySearchWithGeo,
  reverseGeocoding,
  searchLegalEntity,
  selectItinerary,
  fetchAttendantFromRadarAli,
  attendantRead,
  attendantServiceUnitRead,
  fetchOpenStreetMapId,
  cityRead,
  seedCityGovernmentTransparency,
  cityCensusByAreaRead,
  cityCensusByPopulationRead,
  fetchLatestCityCensus,
  cityIbgeCensusRead,
  seedCityCensus,
  legalEntityUnnestCnaeRead,
  cnaeRead,
  cnaeClassRead,
  cnaeDivisionRead,
  cnaeGroupRead,
  cnaeSectionRead,
  cnaeSegmentRead,
  cnaeSubclassRead,
  countryRead,
  fetchCourseProduct,
  courseProductRead,
  courseRegisterIndividualRead,
  fetchDataSourceHelpers,
  dataSourceRead,
  establishmentRead,
  individualAnonymization,
  individualCpfValidator,
  individualFetch,
  individualRead,
  geocodeAddress,
  internalGeocode,
  streetSearchRefreshViews,
  cnpjValidator,
  createLegalEntitySearchIndex,
  legalEntityFetch,
  legalEntityRead,
  attendanceRefreshViews,
  disproportionatePublicRead,
  legalEntityAttendanceFetch,
  legalEntityAttendanceRead,
  legalEntityAttendanceAttendantRead,
  legalEntityAttendanceDataSourceRead,
  prependCommaAtCnaes,
  legalEntityCnaeRead,
  seedLegalEntityCnaes,
  legalEntityGeoprocessingAfterProcess,
  generateHeatmapData,
  courseEvasionRead,
  eadReadCompany,
  eadReadEnrollment,
  eadReadStudent,
  eadReadStudentCompany,
  mapDemarcationExportEntities,
  mapDemarcationReport,
  legalEntityGeoprocessingProcess,
  radar,
  legalEntityGeoprocessingRead,
  legalEntityIndividualRead,
  fetchLegalEntityIndicators,
  legalEntityIndicatorsRead,
  legalNatureRead,
  macroRegionRead,
  mesoRegionRead,
  microRegionRead,
  migrationMigrateOlap,
  migrationMigrateOltp,
  neighborhoodLowerQualityBoundaries,
  neighborhoodNewShapes,
  neighborhoodProcess,
  neighborhoodRead,
  seedNeighborhoodNullableCenter,
  neighborhoodStreetIntersectionProcess,
  neighborhoodStreetIntersectionRead,
  deregisterDevice,
  getNotifications,
  getNotificationSettings,
  readNotification,
  registerNewDevice,
  sendNotification,
  sendTimeSeriesNotification,
  updateTimeSeriesNotificationSettings,
  reportRead,
  reportOutputRead,
  segmentRead,
  serviceUnitGeocode,
  serviceUnitGeoprocessingRead,
  fetchOpenStreetMapStateId,
  stateRead,
  fetchAllRoads,
  fetchAllStreetsAndWays,
  fetchAllSuburbs,
  fetchStreetsAndWays,
  fetchSuburbs,
  streetFullGeocoding,
  streetRead,
  refreshPgRoutingNetwork,
  seedBrasiliaAbbreviatures,
  seedStreetCollection,
  seedStreetRoadReference,
  fetchSummary,
  abortPendingUserQueries,
  systemCheck,
  systemCheckImports,
  importCagedData,
  systemImportEad,
  importFtpPostgresDumps,
  systemPrepareCache,
  systemPropagateToOlap,
  systemExportRfbGeoSftp,
  systemImportSebraeNaSuaEmpresa,
  timeSeriesRead,
  fetchLegalEntitiesCoordinates,
  userLogRead,
  wayRead,
  disproportionateAttendancesRead,
  legalEntityAttendanceLastMonthRead,
  legalEntityAttendanceLastYearRead
]
