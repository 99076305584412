import {
  count,
  entity,
  equals,
  query,
  string,
  truthy,
  distinct,
  number,
  between,
  every,
  ternary,
  isNull,
  some,
  notNull,
  notEquals,
} from '~/prix'
import { getDateRangeFilter } from './utils'
import { FilterHighlight } from '../legalEntityGeoprocessingMapLevels.data'
import { format } from 'date-fns'

export default function legalEntityAttendanceAllCoursesEadIndexQuery({
  groupColumn,
  idColumn,
  id,
  filter: filterIncoming,
}: {
  groupColumn: string
  idColumn: string | null
  id: string | null
  filter?: FilterHighlight | null
}) {
  const filter = filterIncoming ? getDateRangeFilter(filterIncoming) : null

  const currentDate = new Date()
  const firstDayOfCurrentYear = new Date(currentDate.getFullYear(), 0, 1)
  const firstDayOfCurrentYearFormatted = format(firstDayOfCurrentYear, 'yyyy-MM-dd')

  return query('legalEntity')
    .select({
      geoId: entity('legalEntityGeoprocessing').property(groupColumn),
      count: count(
        distinct(
          ternary(
            every(
              equals(
                entity('legalEntity').property('registrationStatus'),
                string().value('active'),
              ),
              isNull(entity('legalEntity').property('deletedAt')),
            ),
            entity('legalEntity').property('id'),
            number().value(null as any),
          ),
        ),
      ),
      highlight: count(
        distinct(
          ternary(
            every(
              equals(
                entity('legalEntityAttendanceDataSource').property('dataSourceId'),
                number().value(5),
              ),
              between(
                entity('courseRegisterIndividual').property('registerDate'),
                !filter
                  ? string().value(firstDayOfCurrentYearFormatted)
                  : string().value(filter.startDate),
                !filter ? entity('dataSource').property('endDate') : string().value(filter.endDate),
              ),
              notEquals(entity('legalEntity').property('state'), string().value('EX')),
            ),
            entity('legalEntity').property('id'),
            number().value(null as any),
          ),
        ),
      ),
    })
    .join({
      current: entity('legalEntity').property('id'),
      target: entity('legalEntityGeoprocessing').property('legalEntityId'),
      join: 'left',
    })
    .join({
      current: entity('legalEntity').property('id'),
      target: entity('courseRegisterIndividual').property('legalEntityId'),
      join: 'left',
    })
    .join({
      current: entity('legalEntity').property('id'),
      target: entity('legalEntityAttendance').property('legalEntityId'),
      join: 'left',
    })
    .join({
      current: entity('legalEntityAttendance').property('id'),
      target: entity('legalEntityAttendanceDataSource').property('legalEntityAttendanceId'),
      join: 'left',
    })
    .join({
      current: entity('legalEntityAttendanceDataSource').property('dataSourceId'),
      target: entity('dataSource').property('id'),
      join: 'left',
    })
    .join({
      current: entity('legalEntityGeoprocessing').property('streetId'),
      target: entity('street').property('id'),
      join: 'left',
    })
    [idColumn === 'neighborhoodId' ? 'join' : 'dummy']({
      current: entity('street').property('id'),
      target: entity('neighborhoodStreetIntersection').property('streetId'),
      join: 'inner',
    })
    .join({
      current: entity('legalEntityGeoprocessing').property('countryId'),
      target: entity('country').property('id'),
      join: 'left',
    })
    .join({
      current: entity('legalEntityGeoprocessing').property('stateId'),
      target: entity('state').property('id'),
      join: 'inner',
    })
    .join({
      current: entity('legalEntityGeoprocessing').property('cityId'),
      target: entity('city').property('id'),
      join: 'left',
    })
    .where(
      ...[
        idColumn !== null && id !== null && idColumn === 'neighborhoodId'
          ? equals(entity('neighborhoodStreetIntersection').property(idColumn), string().value(id))
          : null,

        idColumn !== null &&
        id !== null &&
        idColumn !== 'countryIsoCode' &&
        idColumn !== 'neighborhoodId'
          ? equals(entity('legalEntityGeoprocessing').property(idColumn), string().value(id))
          : null,

        notNull(entity('legalEntityGeoprocessing').property('cityId')),

        idColumn === 'countryIsoCode'
          ? equals(entity('country').property('iso'), number().value(Number(id!)))
          : null,
      ].filter(truthy),
    )
    .limit(10000)
}
