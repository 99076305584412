import {
  count,
  entity,
  equals,
  number,
  query,
  string,
  likeAny,
  boolean,
  truthy,
  notNull,
  isNull,
  distinct,
  between,
  every,
} from '~/prix'
import { optionsBooleanValue } from '../legalEntityGeoprocessing/map/legalEntityGeoprocessingMapLevels.data'
import { FilterHighlight } from '~/packages/legalEntityGeoprocessing/map/legalEntityGeoprocessingMapLevels.data'
import { DefinedOption } from '../legalEntityGeoprocessing/map/menu/legalEntityGeoprocessingMapMenu.data'
import { extractDates } from '../legalEntityGeoprocessing/map/definedQueries/utils'

interface CountNeighborhoodLegalEntities {
  by: string
  id: string
  filter: FilterHighlight | null
  highlight: FilterHighlight | null
  definedOption: DefinedOption
}

/* 
Query criada para apresentar valor total a nível de neighborhood visualizando ruas
Motivo (exemplo): Ao visualizar o bairro vila nova usando essa query temos um total de 16 empresas, numeração coerente
a mostrada no nível anterior city. Utilizando da nossa forma padrão o valor total na seleção é de 99
pois ele conta de todas as ruas presentes no bairro e não apenas as que possuem o id desse bairro
*/

export default function countNeighborhoodLegalEntities({
  by,
  id,
  filter,
  highlight,
  definedOption,
}: CountNeighborhoodLegalEntities) {
  const [filterStartDate, filterFinalDate] =
    filter?.value && typeof filter.value === 'string' ? extractDates(filter.value) : [null, null]
  const [highlightStartDate, highlightFinalDate] =
    highlight?.value && typeof highlight.value === 'string'
      ? extractDates(highlight.value)
      : [null, null]

  return query('legalEntity')
    .select({
      count: count(entity('legalEntity').property('id')),
      highlight:
        highlight?.type === 'attendance' &&
        highlight?.value &&
        !highlight?.courseProduct &&
        highlight.eadOption !== 'portfolio' &&
        highlightStartDate &&
        highlightFinalDate
          ? count(
              every(
                between(
                  entity('courseRegisterIndividual').property('registerDate'),
                  string().value(highlightStartDate),
                  string().value(highlightFinalDate),
                ),
                equals(
                  entity('legalEntityAttendanceDataSource').property(`dataSourceId`),
                  number().value(Number(highlight?.attendanceSource)),
                ),
              ),
            )
          : highlight?.type === 'attendance' &&
            highlight?.value &&
            highlight.eadOption === 'portfolio' &&
            !highlight?.courseProduct &&
            highlightStartDate &&
            highlightFinalDate
          ? count(
              every(
                between(
                  entity('courseRegisterIndividual').property('registerDate'),
                  string().value(highlightStartDate),
                  string().value(highlightFinalDate),
                ),
                equals(
                  entity('courseRegister').property(`isActivePortfolio`),
                  boolean().value(true),
                  entity('legalEntityGeoprocessing').property('legalEntityId'),
                ),
              ),
            )
          : highlight?.type === 'attendance' &&
            highlight?.courseProduct &&
            highlight?.value &&
            highlightStartDate &&
            highlightFinalDate
          ? count(
              every(
                between(
                  entity('courseRegisterIndividual').property('registerDate'),
                  string().value(highlightStartDate),
                  string().value(highlightFinalDate),
                ),
                equals(
                  entity('courseRegisterIndividual').property('productCode'),
                  string().value(highlight?.courseProduct),
                  entity('legalEntityGeoprocessing').property('legalEntityId'),
                ),
              ),
            )
          : highlight?.type === 'size' && highlight?.value
          ? count(
              equals(
                entity('legalEntity').property(highlight?.type),
                string().value(`${highlight.value!}`),
              ),
            )
          : highlight?.type === 'cnaes' && highlight?.value
          ? count(
              likeAny(
                entity('legalEntity').property(highlight?.type),
                highlight?.value
                  .toString()
                  .split(',')
                  .map(cnae_id => string().value(`%,${cnae_id!}%`)),
                { sensitive: true },
              ),
            )
          : highlight?.type === 'legalNatureId' && highlight?.value
          ? count(
              equals(
                entity('legalEntity').property(highlight?.type),
                string().value(`${highlight.value!}`),
              ),
            )
          : highlight?.type === 'segment' && highlight?.value
          ? count(
              likeAny(
                entity('legalEntity').property('cnaes'),
                highlight?.value
                  .toString()
                  .split(',')
                  .map(cnae_id => string().value(`%,${cnae_id!}%`)),
                { sensitive: true },
              ),
            )
          : optionsBooleanValue.includes(`${highlight?.type}`) && highlight?.value
          ? count(equals(entity('legalEntity').property(highlight?.type), boolean().value(true)))
          : number().value(null as unknown as number),
    })
    .join({
      current: entity('legalEntity').property('id'),
      target: entity('legalEntityGeoprocessing').property('legalEntityId'),
      join: 'inner',
    })
    .join({
      current: entity('legalEntityGeoprocessing').property('neighborhoodId'),
      target: entity('neighborhood').property('id'),
      join: 'inner',
    })
    [
      (filter?.type === 'attendance' && filter?.value) ||
      (highlight?.type === 'attendance' && highlight?.value)
        ? 'join'
        : 'dummy'
    ]({
      current: entity('legalEntityGeoprocessing').property('legalEntityId'),
      target: entity(`courseRegisterIndividual`).property('legalEntityId'),
      join: 'left',
    })
    [
      (filter?.type === 'attendance' && filter?.courseProduct) ||
      (highlight?.type === 'attendance' && highlight?.courseProduct)
        ? 'join'
        : 'dummy'
    ]({
      current: entity('courseRegisterIndividual').property('courseProductId'),
      target: entity(`courseProduct`).property('id'),
      join: 'left',
    })
    [
      (filter?.type === 'attendance' && filter?.attendanceSource) ||
      (highlight?.type === 'attendance' && highlight?.attendanceSource)
        ? 'join'
        : 'dummy'
    ]({
      current: entity('legalEntity').property('id'),
      target: entity('legalEntityAttendance').property('legalEntityId'),
      join: 'left',
    })
    [
      (filter?.type === 'attendance' && filter?.attendanceSource) ||
      (highlight?.type === 'attendance' && highlight?.attendanceSource)
        ? 'join'
        : 'dummy'
    ]({
      current: entity('legalEntityAttendance').property('id'),
      target: entity('legalEntityAttendanceDataSource').property('legalEntityAttendanceId'),
      join: 'left',
    })
    .where(
      ...[
        notNull(entity('neighborhood').property('center')),
        equals(entity('legalEntityGeoprocessing').property(by), string().value(id)),
        filter?.type === 'size' && filter?.value
          ? equals(
              entity('legalEntity').property(`${filter?.type}`),
              string().value(`${filter?.value!}`),
            )
          : null,

        filter?.type === 'attendance' && filter?.value && filter?.attendanceSource
          ? equals(
              entity('legalEntityAttendanceDataSource').property(`dataSourceId`),
              number().value(Number(filter?.attendanceSource)),
            )
          : null,

        filter?.type === 'attendance' && filter?.courseProduct && filter.value
          ? equals(
              entity('courseRegisterIndividual').property('productCode'),
              string().value(filter?.courseProduct),
            )
          : null,

        filter?.type === 'attendance' &&
        filter?.value &&
        filter.eadOption === 'portfolio' &&
        !filter?.courseProduct
          ? equals(entity('courseProduct').property('isActivePortfolio'), boolean().value(true))
          : null,

        filter?.type === 'cnaes' && filter?.value
          ? likeAny(
              entity('legalEntity').property(filter?.type),
              filter?.value
                .toString()
                .split(',')
                .map(cnae_id => string().value(`%,${cnae_id!}%`)),
              { sensitive: true },
            )
          : null,
        filter?.type === 'segment' && filter?.value
          ? likeAny(
              entity('legalEntity').property('cnaes'),
              filter?.value
                .toString()
                .split(',')
                .map(cnae_id => string().value(`%,${cnae_id!}%`)),
              { sensitive: true },
            )
          : null,
        filter?.type === 'legalNatureId' && filter?.value
          ? equals(
              entity('legalEntity').property(`${filter?.type}`),
              string().value(`${filter?.value!}`),
            )
          : null,
        optionsBooleanValue.includes(`${filter?.type}`)
          ? equals(entity('legalEntity').property(`${filter?.type}`), boolean().value(true))
          : null,

        filterStartDate &&
        filterFinalDate &&
        filter &&
        filter.type === 'attendance' &&
        filter.attendanceSource &&
        filter.attendanceSource !== '5'
          ? between(
              entity('legalEntityAttendance').property('startDate'),
              string().value(filterStartDate),
              string().value(filterFinalDate),
            )
          : null,

        filterStartDate && filterFinalDate && filter?.type === 'attendance' && filter?.courseProduct
          ? between(
              entity('courseRegisterIndividual').property('registerDate'),
              string().value(filterStartDate),
              string().value(filterFinalDate),
            )
          : null,
      ].filter(truthy),
    )
    .limit(100000)
}
