import React, { useEffect, useMemo } from 'react'
import styled from 'styled-components'
import Handle from '~/prix/react/components/handle'
import useItem from '~/prix/react/hooks/item'
import { formatAsBrIntegerNumber } from '~/prix/utils/types/number'
import attendanceQuery from '../queries/attendance.query'
import { format } from 'date-fns'
import useItems from '~/prix/react/hooks/items'
import additionalInformationQuery from '~/packages/legalEntityGeoprocessing/map/additionalInformation/additionalInformation.query'

export const Items = styled.div`
  display: flex;
  flex-direction: column;
  background-color: var(--p-default, #005eb8);
  box-shadow: 0px 32px 30px rgba(20, 46, 82, 0.2);
  padding: 12px;
  border-radius: 0 0 12px 12px;
  height: 100%;
  justify-content: center;
  min-height: 119px;
`

export const Item = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  justify-content: space-between;
  align-items: center;

  > span {
    width: 50%;
  }

  @media (min-width: 1025px) {
    align-items: center;
    gap: 20px;
    padding: 0 12px;
  }

  .first {
    color: #fff;
    font-weight: 700;
    font-size: 33px;
  }

  .second {
    color: #fff;
    font-size: 13px;
    font-weight: 400;

    @media (max-width: 1160px) {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
`

export default function DashboardGridItemAttendance({
  userGeoStateAbbreviation,
  dataSourceId,
  isLastYearQuery,
  handleCountAttendances,
  hasUserRoles,
  dataSourceEndDate,
}: {
  userGeoStateAbbreviation?: string | null
  dataSourceId?: number
  isLastYearQuery?: boolean
  handleCountAttendances?: any
  hasUserRoles?: boolean
  dataSourceEndDate?: any
}) {
  const totalAttendance = useItem(
    () =>
      attendanceQuery({
        stateAbbreviation: userGeoStateAbbreviation ? userGeoStateAbbreviation : undefined,
        dataSourceId,
        isLastYearQuery,
      }),
    [userGeoStateAbbreviation, dataSourceId],
    { cache: 60 * 60 * 24 },
  )

  const currentDate = new Date()

  const firstDayOfCurrentYear = new Date(currentDate.getFullYear(), 0, 1)
  const firstDayOfCurrentYearFormatted = format(firstDayOfCurrentYear, 'yyyy-MM-dd')

  //TODO Este tratamento é realizado devido a diferença de fusos entre os ambientes locais e os na nuvem.
  function treatLastRegisterDate(date: string | null) {
    const lastRegisterDate = date ? new Date(date) : null
    // if (_lastRegisterDate) {
    //   const _date = new Date(_lastRegisterDate)
    //   // _date.setHours(23)
    // return new Date(_date.getTime() + 3 * 60 * 60 * 1000)
    return lastRegisterDate
    // }
  }

  const filter = useMemo(() => {
    if (dataSourceId && dataSourceEndDate && firstDayOfCurrentYearFormatted) {
      const dataSourceEndDateObject = treatLastRegisterDate(dataSourceEndDate)

      const dataSourceEndDateFormatted = dataSourceEndDateObject
        ? format(dataSourceEndDateObject, 'yyyy-MM-dd')
        : null

      return {
        type: 'attendance',
        attendanceSource: dataSourceId,
        value: `${firstDayOfCurrentYearFormatted},${dataSourceEndDateFormatted}`,
      }
    }
    return null
  }, [firstDayOfCurrentYearFormatted, dataSourceId, dataSourceEndDate])

  const additionalInformationsResult = useItems(
    () =>
      dataSourceId && filter ? (additionalInformationQuery('country', '30', filter) as any) : null,
    [filter, dataSourceId],
    {
      cache: 60 * 60 * 24 * 7,
      autoLoad: Boolean(dataSourceId && filter && dataSourceEndDate),
    },
  )

  useEffect(() => {
    if (
      hasUserRoles &&
      totalAttendance.item &&
      totalAttendance.item.totalLegalEntity !== undefined &&
      totalAttendance.item?.totalLegalEntity !== 0
    ) {
      handleCountAttendances(totalAttendance.item?.totalLegalEntity)
    }

    if (
      (hasUserRoles &&
        totalAttendance.item &&
        totalAttendance.item.totalLegalEntity === undefined) ||
      totalAttendance.item?.totalLegalEntity === 0
    ) {
      handleCountAttendances(0)
    }
  }, [totalAttendance.item, dataSourceId, hasUserRoles])

  const totalAttendanceCount = useMemo(() => {
    if (dataSourceId === 0 && totalAttendance.item) {
      const totalLegalEntity = totalAttendance.item?.totalLegalEntity

      return Number(totalLegalEntity)
    }

    if (totalAttendance.item && additionalInformationsResult?.items) {
      const totalLegalEntity = totalAttendance.item?.totalLegalEntity
      const additionalInformationCount = additionalInformationsResult?.items[0]?.count

      if (totalLegalEntity == null && additionalInformationCount == null) {
        return null
      }

      if (additionalInformationCount == null) {
        return totalLegalEntity
      }

      if (totalLegalEntity == null) {
        return additionalInformationCount
      }

      return Number(totalLegalEntity) + Number(additionalInformationCount)
    }

    return null
  }, [totalAttendance.item?.totalLegalEntity, additionalInformationsResult?.items])

  return (
    <Handle
      isLoading={
        totalAttendance.isLoading ||
        additionalInformationsResult.isLoading ||
        totalAttendanceCount == null
      }
      error={totalAttendance.error}
      style={{ minHeight: '100px' }}
    >
      <Items>
        <Item>
          <span className='first'>
            {formatAsBrIntegerNumber(Number(totalAttendance.item?.total))}
          </span>
          <span className='second'>Atendimentos realizados</span>
        </Item>

        <Item>
          <>
            <span className='first'>
              {totalAttendanceCount
                ? formatAsBrIntegerNumber(Number(totalAttendanceCount))
                : undefined}
            </span>
            <span className='second'>
              {dataSourceId === 0 ? 'Empresas atendidas' : 'Empresas geocodificadas atendidas'}
            </span>
          </>
        </Item>
      </Items>
    </Handle>
  )
}
